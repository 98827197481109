@import "../variables.module";

.providers-container {
  padding-top: $padding;
}

.games-heading-title {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 20px;

  h1 {
    font-size: 17px;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    padding: 12px 14px;
    cursor: pointer;

    border-radius: 66px;
    border: 1px solid #212649;
    background:
      radial-gradient(
        76.33% 100% at 50% 100%,
        #262c52 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      #202546;

    transition: all 0.1s ease-in-out;

    svg {
      width: 15px;
    }

    &:hover,
    &.active {
      cursor: pointer;
      background: #0077db;
      border: 1px solid #0077db;

      p {
        color: white;
      }
    }

    &:hover {
      svg {
        path {
          stroke: white;
          stroke-opacity: 1;
        }
      }
    }
  }

  .games-container {
    display: flex;
    align-items: center;
    gap: 10px;

    h1 {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
    }

    svg {
      width: 22.318px;
      height: 24px;
    }
  }
}

.mobile-option-display {
  background-color: #202648;
  padding: 15px;
  border-radius: $radius;

  .mobile-toggles-content {
    display: flex;
    gap: 20px;

    @media (max-width: 415px) {
      flex-direction: column;
      margin-bottom: 20px;

      .mobile-toggle-container {
        margin-bottom: 0px;
      }
    }
  }

  .mobile-toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    margin-left: -10px;

    span {
      font-size: 12px;
    }

    &__turn-off {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .mobile-play-btn-container {
    .mobile-register-login-container {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
}

.container {
  background-color: $secondaryBackgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: auto;
  margin-bottom: $padding * 2;

  @media (max-width: $breakPointOne) {
    margin: 0px;
  }

  .game-content {
    position: relative;
    width: 100%;

    .aspect-ratio {
      position: relative;
      width: 100%;

      @media (min-width: 1000px) {
        padding-bottom: var(--aspectratio);
      }

      .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        width: 50px;
        height: 50px;
      }

      .slot-game-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #141931;
        z-index: 998;

        iframe {
          width: 100%;
          height: 100%;
        }

        &.mobile-view {
          display: none;
        }

        &.active-mobile-view {
          display: block;
          position: fixed;
          width: auto;
          height: 100%;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 100;

          @media (max-width: $breakPointOne) {
            padding-bottom: 68px;
            padding-top: 68px;
          }
        }

        &.full-screen {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 100;
          padding: 0px;
          z-index: 5100;
          position: fixed;
        }
      }
    }

    .overlay {
      position: absolute;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 80px;
      background-color: rgba(0, 0, 0, 0.5);

      opacity: 1;

      transition: opacity 300ms $easeInOut3;

      &.active-overaly {
        opacity: 1;
      }

      &.close {
        display: none;
        pointer-events: none;
      }

      .button-content {
        display: flex;
        gap: 10px;
      }
    }

    .slot-game-bar {
      background-color: $sideBarBackground;
      height: 80px;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;
      align-items: center;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      &.mobile-three {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
        height: 140px !important;
        gap: 10px;
        padding: 15px;
      }

      .slot-game-bar-left-container {
        display: flex;
        gap: 40px;
        align-items: center;

        svg {
          width: 131px;
        }

        .divider-line {
          height: 40px;
          width: 2px;
          background: #10152b;
        }

        &.mobile-one {
          gap: 30px;

          svg {
            width: 120px;
          }
        }

        &.mobile-two {
          gap: 20px;

          svg {
            width: 100px;
          }
        }

        &.mobile-three {
          gap: 0px;
          flex-direction: row-reverse;
          justify-content: space-between;
          width: 100%;

          svg {
            width: 80px;
          }

          .divider-line {
            display: none;
          }
        }
      }

      .game-info {
        cursor: default;
        flex-shrink: 0;

        h2 {
          color: $inputLabelColorLight;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }

        .game-link-content {
          display: inline-flex;
          padding: 2px 0px;
          justify-content: flex-start;
          align-items: center;
          gap: 7px;
          h3 {
            color: rgba(232, 229, 255, 0.5);
            font-size: 13px;
            font-style: normal;
            line-height: normal;
          }
          svg {
            width: 11px;
            margin-top: 4px;
            display: none;
            fill: rgba(232, 229, 255, 0.85);
          }
          &:hover {
            text-decoration: underline;
            h3 {
              color: rgba(232, 229, 255, 0.85);
            }
            svg {
              display: block;
            }
          }
        }
        &.mobile-three {
          h2 {
            font-size: 12px;
          }

          h3 {
            font-size: 11px;
          }
        }
      }

      .game-options {
        display: flex;
        gap: 20px;
        align-items: center;

        .game-options-buttons {
          display: flex;
          gap: 0px;
          align-items: center;
        }

        &.mobile-three {
          justify-content: space-between;
          width: 100%;

          .game-options-buttons {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.error-display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  z-index: 999;
  color: $fontColorTwo;

  .error-content {
    p {
      color: $fontColorTwo;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 450px;
    line-height: 1.1em;
    padding: 20px;

    svg {
      width: 50px;
    }

    .demo-error-button-container {
      display: flex;
      gap: 10px;
    }
  }

  @media (max-width: $breakPointOne) {
    position: relative;
    border-radius: $radius;
  }
}

.demo-toggle {
  display: flex;
  align-items: center;
  gap: 10px;

  .real-play-span {
    color: $fontColorFive;
    font-size: 13px;
    font-style: normal;
    line-height: normal;
    margin-right: -10px;
    margin-left: 40px;

    &.mobile-one {
      margin-left: 20px;
    }

    &.mobile-two {
      margin-left: 0px;
    }

    &.mobile-three {
      margin-left: 0px;
      font-size: 12px;
    }

    &.demo-mode {
      color: #e8e5ff;
    }
  }

  span {
    color: $inputLabelColorLight;
    font-size: 13px;
    font-style: normal;
    line-height: normal;

    &.demo-mode-real {
      color: #5e679e;
    }
  }

  &.mobile-three {
    span {
      font-size: 12px;
    }
  }

  &.guest {
    pointer-events: none;

    .real-play-span,
    div,
    span {
      opacity: 0.5;
    }

    .real-play-demo {
      color: #fff;
      opacity: 1;
    }
  }
}

.game-details-container {
  padding-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;

  &__slot-mobile-display {
    margin-top: 20px;
  }
}

.all-slots-container {
  padding-top: 25px;

  .slots-list {
    display: flex;
    gap: 20px;

    .quick-link-content {
      border: 1px solid #fff;
      display: block;
      position: relative;
      width: 100px;
    }
  }

  .more-slots-container {
    display: flex;
    justify-content: center;
    padding-top: $padding;
  }
}

.games-might-like-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion {
  margin-bottom: 20px;

  > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
