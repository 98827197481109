@import "../variables.module";

$transition: all 0.3s ease;

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding: $Dmw/2;
}

.toggle-content {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
  font-size: $fontSizeFour;
  font-family: $boldFont;

  &.no-name {
    gap: 0px;
  }
}

.toggle-switch {
  width: 60px;
  height: 30px;
  border-radius: 25px;
  position: relative;
  transition: $transition;
  overflow: hidden;
  display: flex;
  background-color: $fourthBackground;
  border: none;

  &:hover {
    cursor: pointer;
  }

  .gradient-item {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: $transition;
    @include gradient(80deg, $secondary-fill-color, $secondary-stroke-color);
  }

  .switch-icon {
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 23px;
    background-color: #fff;
    margin: 1px;
    left: 3px;
    top: 2px;
    z-index: 1;
    transition: $transition;
  }

  &.active-toggle,
  input:checked + & {
    .switch-icon {
      left: 32px;
    }

    .gradient-item {
      opacity: 1;
    }
  }

  &.gradient-blue {
    width: 44px;
    height: 26px;
    background-color: #ffffff1b;

    .gradient-item {
      @include gradient(80deg, $blue, $primary-stroke-color);
    }

    .switch-icon {
      width: 20px;
      height: 20px;
    }

    &.active-toggle,
    input:checked + & {
      .switch-icon {
        left: 20px;
      }
    }
  }
}
