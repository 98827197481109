@import "../variables.module";

@mixin color-opacity($color, $opacity) {
  $rgba-color: rgba($color, $opacity);
  color: $rgba-color;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: $padding;
  // padding: $Dmw/2;
  // flex-grow: 1;
  position: relative;

  &.no-bottom-padding {
    padding-bottom: 0px;
  }

  &.error input {
    border-color: $popupRed !important;
  }

  &.input-error {
    input {
      border-color: $popupRed !important;
    }
  }

  .error-item {
    color: $popupRed;
  }

  &.row {
    flex: 1;
  }

  .label-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;

    label {
      color: #e8e5ff;
      font-family: $boldFont;
      font-size: $fontSizeFour;
    }

    .label-variation {
      color: $fontColor;
    }

    .value-display {
      font-size: 12px;
      // padding-bottom: 4px;
      display: flex;
      align-items: center;

      .value-icon {
        width: 15px;
      }

      p {
        color: $secondaryFontColor;
        padding-left: 5px;
      }
    }
  }

  .container-icon {
    position: relative;

    span {
      position: absolute;
      z-index: 10;
      top: 15px;
      left: 10px;
      display: flex;
      gap: 6px;
      align-items: center;

      color: rgba(232, 229, 255, 0.75);

      font-size: 13px;
      font-weight: 500;
      svg {
        z-index: 10;
        height: 13px;
        width: 18px;
      }
    }
  }

  input,
  select {
    border: 1px solid #282f58;
    background: radial-gradient(
      ellipse farthest-corner at 50% 100%,
      #262c51,
      #1d2341
    );
    border-radius: 6px;
    height: 50px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: $primaryFontMedium;
    color: $white;
    transition:
      border-color 300ms $easeInOut3,
      opacity 300ms $easeInOut3;

    // opacity: 0.95;
    &:focus {
      border-color: #2099ff;
      outline: none !important;
      // opacity: 1;
    }

    &::placeholder {
      color: $fontColorTwo;
      // font-style: italic;
      color: #565c7d;
    }
  }

  .row-label {
    position: absolute;
  }

  &.input-drop-down {
    width: 100%;
    flex: 1 1 0;

    .custom-width {
      max-width: 260px;
      width: 100%;
    }
  }

  select {
    cursor: pointer;
    padding: 0;
    padding-left: 10px;
    padding-right: 35px; // Increased right padding to make room for dropdown icon
    // background-color: $btnFill;
    width: 100%;
    background: radial-gradient(
      ellipse farthest-corner at 50% 100%,
      #262c51,
      #1d2341
    );
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23565C7D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"),
      radial-gradient(ellipse farthest-corner at 50% 100%, #262c51, #1d2341);
    background-repeat: no-repeat, no-repeat;
    background-position:
      right 15px center,
      center;
    background-size:
      10px,
      100% 100%;

    option {
      border-radius: $radius;
      background-color: $secondaryBackgroundColor;
    }
  }

  &.error select {
    border-color: $popupRed;
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $inputBorder;
    right: 20px;
    width: 20px;
    height: 100%;
    font-size: 20px;
    font-family: $boldFont;

    .position-default {
      // position: absolute;
      left: 20px;
    }
  }

  &.icon-padding {
    .icon {
      padding-top: 29px;
    }
  }

  &.left-icon {
    .inline-btn-container {
      input {
        padding-left: 40px;
      }
    }
  }

  .inline-value-icon {
    // border: 1px solid #fff;
    width: 20px;
    display: flex;

    align-items: center;
    position: absolute;
    pointer-events: none;
    // flex-grow: 1;
    // padding: 10px;
    margin-left: 15px;
    height: 100%;

    &.readonly {
    }
  }

  //----------------DICE
  &.dice-game-input {
    padding-bottom: 0;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .icon {
      color: #303862;
      padding-top: 0;
      top: 0;
      right: 5%;

      @media (max-width: $breakPointTwo) {
        right: 4%;
        transform: scale(0.8) translateY(4%);
      }
    }
  }

  //-----^^^^DICE

  //SEARCH

  &.search {
    padding: 0;
    height: 44px;
    width: 100%;
    margin-top: 24px;

    margin-bottom: 10px;

    input {
      background-color: $thirdBackground;
      padding-left: 35px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 44px;

      &::placeholder {
        font-style: normal;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    .label-heading {
      padding: 0;
    }

    .icon {
      left: 10px;
      top: 0px;
      bottom: 0px;
      width: 25px;

      display: flex;
      align-items: center;

      svg {
        width: 100%;
        fill: $fontColorTwo;
      }
    }
  }

  &.search-challenges {
    padding: 0;
    height: 44px;
    width: 100%;
    margin-top: 10px;

    margin-bottom: 10px;

    input {
      background-color: $thirdBackground;
      padding-left: 35px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 44px;

      &::placeholder {
        font-style: normal;
        color: rgba(232, 229, 255, 0.75) !important;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    .label-heading {
      padding: 0;
    }

    .icon {
      left: 10px;
      top: 0px;
      bottom: 0px;
      width: 25px;

      display: flex;
      align-items: center;

      svg {
        width: 100%;
        fill: $fontColorTwo;
      }
    }
  }

  &.search-input {
    // height: 40px;\
    width: 300px;

    &.full-width {
      width: 100%;
      max-width: 575px;
    }

    margin-top: 0px;

    @media (max-width: $breakPointTwo) {
      width: 100%;
    }
  }

  //SEARCH

  //Has buttons inside the same input container
  .inline-btn-container {
    position: relative;
    // border: 1px solid $inputBorder;
    // background-color: $btnFill;
    // border-radius: $borderRadius;
    border-radius: 6px;
    border: 1px solid $fourthBackground;
    // background: #1d2341;
    background: radial-gradient(
      ellipse farthest-corner at 50% 100%,
      #262c51,
      #1d2341
    );

    display: flex;
    overflow: hidden;
    justify-content: space-between;

    // width: 100px;
    transition: border 300ms $easeInOut3;

    input {
      padding: 15px;
      border: none;
      // flex-grow: 1;
      width: 100%;
      // border: none;
      height: 50px;
      background: transparent;

      // width: auto;
      &.icon-space {
        padding-left: 40px;
      }

      &.current-winnings {
        color: #7dd933;
        pointer-events: none;
      }

      &:focus {
        border-color: transparent;
      }
    }

    &:focus-within {
      border-color: #2099ff;
    }

    &.inline-error {
      border: 1px solid $popupRed;
    }

    input:focus {
      outline: none;

      & ~ .inline-btn-container {
        border-color: #2099ff;
        background-color: #fff;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .btn-container {
      align-items: center;
      flex-grow: 0;
      display: flex;
      gap: 5px;
      margin-right: 15px;
    }
  }

  &.mid-height {
    .inline-btn-container input {
      height: 50px;
    }
  }

  &.crypto-address {
    padding-bottom: 0px;
    margin-bottom: 10px;

    input {
      font-size: $fontSizeFour;
      padding: 20px 15px;
    }

    .icon {
      top: 0;
      bottom: 0;
      display: flex;
      width: 15px;
      align-items: center;

      svg {
        width: 100%;
      }

      // top: 50%;
    }
  }

  &.inline-crypto-address {
    padding: 0;
  }

  &.no-padding {
    padding: 0;
  }

  &.flex {
    flex: 1;
  }

  &.search-dropdown {
    select {
      height: 44px;

      // background-color: $thirdBackground;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.search-dropdown-with-icon {
    select {
      height: 44px;
      width: 280px;
      font-size: 13px;

      padding-left: 88px;

      // background-color: $thirdBackground;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.search-dropdown-with-text {
    select {
      height: 44px;
      width: 280px;
      padding-left: 70px;
      font-size: 13px;

      // background-color: $thirdBackground;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.inline-drop-down {
    padding-bottom: 0;
    width: 75px;
    height: 40px;
  }

  &.search-dropdown-steam {
    // border: 1px solid #fff;
    select {
      color: $secondaryFontColor;
      height: 44px;

      // background-color: $thirdBackground;
      &:hover {
        cursor: pointer;
      }
    }
  }

  select option {
    padding: 10px;
    font-size: 14px;
    // background-color: #f2f2f2;
    padding: 8px;
  }

  &.icon-left {
    // padding-left: 100px;
    .icon {
      right: auto;

      left: 15px;
      padding-top: 14px;
      width: 20px;
      height: auto;
    }

    input {
      padding-left: 40px;
    }
  }

  &.icon-adjustment {
    .icon {
      padding-top: 38px !important;
    }
  }

  &.icon-right {
    // padding-left: 100px;
    .icon {
      // right: auto;
      // left: 15px;
      padding-top: 22px;
      width: 17px;
    }
  }

  &.no-icon-padding {
    .icon {
      padding-top: 0px;
    }
  }

  &.icon-label {
    .icon {
      padding-top: 5px;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.edit-height {
    .icon {
      right: auto;
      left: 10px;
      width: 17px;
    }

    input {
      padding-left: 30px;
    }
  }

  &.edit-height,
  &.edit-height-precent {
    height: auto;
    padding: 0;

    input {
      height: 38px;
      padding-top: 2px;
      padding-bottom: 0px;
      padding-right: 0px;
    }
  }

  &.edit-height-precent {
    .icon {
      right: auto;
      right: 10px;
      width: 17px;
      top: 0;
      font-size: 15px;
    }

    input {
      padding-right: 20px;
    }
  }

  &.f2A-container {
    max-width: 170px;
  }
  &.register-inputs {
    padding-top: 0rem;
    padding-bottom: 0.8rem;

    & > input {
      padding-left: 1rem;
    }

    .label-heading {
      label {
        @include color-opacity($primaryFontColor, 0.75);
        font-size: 12px;
        padding: 0.5rem 0;
      }
    }
  }

  &.register-inputs {
    padding-top: 0rem;
    padding-bottom: 0.8rem;

    & > input {
      padding-left: 1rem;
    }

    .label-heading {
      label {
        @include color-opacity($primaryFontColor, 0.75);
        font-size: 12px;
        padding: 0.5rem 0;
      }
    }
  }

  &.modal-inputs {
    .label-heading {
      label {
        color: $inputLabelColorLight;
        font-size: 13px;
      }
    }

    input {
      border-radius: 6px;
      border: 1px solid #282f58;
      background: radial-gradient(
        ellipse farthest-corner at 50% 100%,
        #262c51,
        #1d2341
      );
      font-size: 13px;

      // font-weight: 600;
      &:not([id="secret"]):focus {
        border-color: #2099ff;
        outline: none !important;
      }
    }

    &.full-width {
      width: 100%;
    }

    &.white-placeholder {
      ::placeholder {
        color: rgba(232, 229, 255, 0.5);
        font-size: 13px;
        font-style: normal;
        // font-weight: 600;
        line-height: normal;
      }
    }

    &.search-modal {
      height: 56px;

      input {
        padding-left: 50px;
        height: 56px;
      }

      .icon {
        left: 24px;
        width: 20px;

        svg {
          width: 100%;
          fill: #5e679e;
        }
      }
    }

    &.no-border {
      input {
        border: none;

        &:focus {
          border: none;
        }
      }
    }
  }

  &.no-margin {
    margin: 0 !important;
  }

  &.row-item-small {
    // width: 5%;
    flex-shrink: 1;
    // border: 1px solid red;
  }

  &.row-item-large {
    flex-shrink: 0;
    flex-basis: 25%;
  }

  &.center-text {
    input {
      text-align: center;
      padding: 0;
    }
  }
}

.reset-file-input[type="file"] {
  line-height: 10px;

  &::-webkit-file-upload-button {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 1280px) {
  .inputWrapper {
    &.search {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }

    &.search-input {
      // height: 40px;\

      &.full-width {
        width: 100%;
        max-width: 100%;
      }

      margin-top: 0px;

      @media (max-width: $breakPointTwo) {
        width: 100%;
      }
    }

    &.search-dropdown-with-icon {
      select {
        height: 44px;
        width: 100%;
        font-size: 13px;

        padding-left: 88px;

        // background-color: $thirdBackground;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &.search-dropdown-with-text {
      select {
        height: 44px;
        width: 100%;
        padding-left: 70px;
        font-size: 13px;

        // background-color: $thirdBackground;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
